import React from 'react'
import { graphql, Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import {
	Container,
	Title,
	Content,
	Button,
  Grid,
  A
} from '../components/styled'
import Quote from '../components/Quote'
import Contact from '../components/Contact'

export default ({ data }) => {
  //console.log(data)
  return (
  <Layout>
    <SEO
      title="Website-Konzept - Machen Sie von Anfang an alles richtig!"
      description="► Website-Konzept: ✓ Zielgruppe verstehen ✓ Unternehmensziele erreichen ✓ User Experience (UX) verbessern ✓ Standort Köln / Bonn ► Jetzt anfragen"
    />
  	<Container>
  		<Container.Inner>
  			<Content>Website-Konzept</Content>
		    <Title as="h1">Machen Sie von Anfang an alles richtig</Title>
		    <Content big>
		    	<p>Mit einem Konzept, das Zielgruppe und Unternehmensziele, wie User Experience (UX) und Conversion Rate Optimierung (CRO) berücksichtigt, schaffen Sie die Basis für den Erfolg Ihrer neuen Webseite oder Landingpage.</p>
          <Button as={Link} primary="true" to="/kontakt/">Website-Konzept anfragen </Button>
		    </Content>
		  </Container.Inner>
	  </Container>
	  <Container>
  		<Container.Inner>
        <Grid className="nb5">
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Zielgruppe verstehen</Title>
            <Content>
              <p>Lernen Sie Ihre Zielgruppe besser kennen. Erkennen Sie wo der Schuh der drückt. Bieten Sie eine passgenaue Lösung.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>Unternehmens&shy;ziele erreichen</Title>
            <Content>
              <p>Erfahren Sie, wie Sie welche Unternehmensziele mit Ihrer neuen Webseite oder Landingpage erreichen können.</p>
            </Content>
          </Grid.Col>
          <Grid.Col className="mb5 w-third-l">
            <Title smaller>User Experience verbessern</Title>
            <Content>
              <p>Bieten Sie Ihren potenzielle Kunden eine gute Erfahrung. Erhalten Sie im Gegenzug Vertrauen. Steigern Sie Ihren Unternehmenserfolg.</p>
            </Content>
          </Grid.Col>
        </Grid>
  		</Container.Inner>
  	</Container>
    <Container>
      <Container.Inner>
        <Quote reverse {...data.allTestimonialsYaml.edges.find(el => el.node.name === 'luxmeyer').node} />
      </Container.Inner>
    </Container>
    <Contact>
      <Title center>Legen Sie jetzt los, mit dem richtigen Konzept</Title>
      <Content big center>
        <p>Ein gutes Website-Konzept liefert die passenden Antworten.</p>
        <Button as={Link} primary="true" to="/kontakt/">Website-Konzept anfragen</Button>
      </Content>
    </Contact>
  </Layout>
)}

export const query = graphql`
  query {
    allTestimonialsYaml(filter: {name: {in: ["luxmeyer", "anwaltverlag"]}}) {
      edges {
        node {
          name
          children: content
          source
          image {
            ...rectangleImage
          }
          logo {
            ...logo
          }
        }
      }
    }
  }
`
